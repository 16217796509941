import React, {useEffect, useState} from 'react';
import SimpleCard from "../component/SimpleCard";
import SimpleTable from "../component/SimpleTable";
import {CAMPAIGNS_LIST} from "../constants/Requests";
import {CircularProgress} from "@mui/material";

const CampaignDashboard = () => {


    const [campaignList, setCampaignList] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const getCampaignsList = async () => {

            const token = localStorage.getItem('token');

            try {
                const response = await fetch(CAMPAIGNS_LIST, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setCampaignList(data.campaigns_list);
                }
            } catch (error) {
                console.error('Error during authentication check:', error);
            } finally {
                setLoading(false); // Set loading to false after the authentication check is complete
            }

        }

        getCampaignsList();

    }, []);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col pt-5">
                <CircularProgress/>

            </div>
        );
    }

    return (

        <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col pt-5">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 w-full max-w-5xl px-4">
                <SimpleCard title={"255"} description={"Campaigns"}/>
                <SimpleCard title={"255"} description={"Campaigns"}/>
                <SimpleCard title={"255"} description={"Campaigns"}/>
            </div>
            <SimpleTable data={campaignList}/>
        </div>

    );

};

export default CampaignDashboard;