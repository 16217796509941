import React, {useState} from 'react';
import {STORE_PAY_QR} from "../constants/Requests";
import {useSelector} from "react-redux";

const StorePayQR = () => {

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const userData = useSelector((state) => state.user);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = userData.token;

        setLoading(true);

        try {
            // Make the request and expect the binary file (zip)
            const response = await fetch(STORE_PAY_QR, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    "code": inputValue
                }),
            });

            // Check if the response is ok
            if (!response.ok) {
                throw new Error('Error fetching the zip file');
            }

            // Convert response to blob
            const blob = await response.blob();

            // Generate a random name for the zip file
            const randomName = `qrcodes_${Math.random().toString(36).substr(2, 9)}.zip`;

            // Create a link element and trigger the download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;

            // Set the random filename for download
            link.setAttribute('download', randomName);

            // Append the link to the body temporarily and trigger the click
            document.body.appendChild(link);
            link.click();

            // Cleanup the link after download
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Error fetching zip:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-2xl font-semibold text-gray-700 mb-6">Fetch Data</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="text"
                            className="w-full px-4 text-black py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter ID"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className={`w-full px-4 py-2 text-white font-semibold rounded-md transition-colors ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                        disabled={loading}
                    >
                        {loading ? (
                            <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </form>

                {data && (
                    <div className="mt-6">
                        <h3 className="text-lg font-bold">Fetched Data:</h3>
                        <pre
                            className="bg-gray-100 p-4 rounded-md overflow-x-auto">{JSON.stringify(data, null, 2)}</pre>
                    </div>
                )}
            </div>
        </div>
    );

};

export default StorePayQR;