import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  token: '',
  id: '',
  profileImage: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setUserID: (state, action) => {
      state.id = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    clearUserData: (state) => {
      state.username = '';
      state.profileImage = '';
      state.id = '';
      state.token = '';
    },
  },
});

export const { setUsername, setProfileImage, clearUserData,setUserID,setUserToken } = userSlice.actions;

export default userSlice.reducer;
