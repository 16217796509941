import React from "react";
import {Edit} from "@mui/icons-material";

const SimpleTable = ({ data }) => (
  <div className="flex flex-col items-center mt-5 min-h-screen">
    <div className="w-full max-w-4xl bg-white rounded-lg shadow-md overflow-hidden">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {["No", "Campaign Image", "Campaign Name", "Last Update", "Current Update", "Edit"].map((heading) => (
              <th
                key={heading}
                className="px-6 py-3 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((campaign, index) => (
            <CampaignRow
              key={campaign.ac_id}
              index={index}
              image={campaign.image}
              name={campaign.store}
              lastUpdate={campaign.lastUpdate}
              currentUpdate={campaign.currentUpdate}
            />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const CampaignRow = ({ index, image, name, lastUpdate, currentUpdate }) => (
  <tr className="border-b hover:bg-gray-100">
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
    <td className="px-6 py-4 whitespace-nowrap">
      <img src={image} alt="Campaign" className="w-12 h-12 rounded-md object-cover" />
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{name}</td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lastUpdate}</td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{currentUpdate}</td>
    <td className="px-6 py-4 whitespace-nowrap text-center">
      <button className="text-blue-500 hover:text-blue-700">
        <Edit />
      </button>
    </td>
  </tr>
);

export default SimpleTable;
