import React, {useState} from 'react';
import {BACKGROUND_IMAGE_CHANGE} from "../constants/Requests";
import {useSelector} from "react-redux";

const Home = () => {
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [processedImageUrl, setProcessedImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.user);


    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
        setImageUrl('');
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = userData.token;
        setLoading(true);

        let processedImage;

        try {
            const randomString = generateRandomString(10); // 10-character random string
            const filePath = `/assets/ai/${randomString}.jpg`;
            if (imageFile) {
                // Upload the image file
                const formData = new FormData();
                formData.append('image', imageFile);
                formData.append("path", filePath);

                const response = await fetch(BACKGROUND_IMAGE_CHANGE, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                processedImage = result.url;
            } else if (imageUrl) {
                // Process the image via URL
                const response = await fetch(BACKGROUND_IMAGE_CHANGE, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({"image_url": imageUrl,"path":filePath}),
                });

                const result = await response.json();
                processedImage = result.url;
            }

            // If the response has a processed image URL, display it
            if (processedImage) {
                setProcessedImageUrl(processedImage);
                setImageFile(null);
            } else {
                alert('Failed to process the image. Please try again.');
            }
        } catch (error) {
            console.error('Error during image processing:', error);
            alert('An error occurred while processing the image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-700 flex justify-center items-center p-4">
            <div className="bg-white text-gray-800 rounded-lg shadow-2xl p-8 w-full max-w-5xl">
                {/* Header */}
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-900">Image Processor</h1>
                <p className="text-center text-gray-500 mb-10">
                    Upload an image or provide a URL, and get a processed version of the image.
                </p>

                <div className="grid grid-cols-2 gap-10">
                    {/* Column 1 - Upload / URL Input */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
                        <h2 className="text-lg font-semibold mb-4">Upload Image or Enter URL</h2>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="mb-4 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0
              file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100"
                        />
                        <div className="relative mb-6">
                            <input
                                type="text"
                                placeholder="Or paste image URL here"
                                value={imageUrl}
                                onChange={(e) => {
                                    setImageUrl(e.target.value);
                                    setImageFile(null);
                                }}
                                className="p-3 w-full border rounded-lg focus:ring focus:ring-blue-200"
                            />
                        </div>
                    </div>

                    {/* Column 2 - Processed Image */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-inner flex flex-col items-center">
                        <h2 className="text-lg font-semibold mb-4">Processed Image</h2>
                        <div className="w-72 h-72 bg-gray-300 rounded-lg flex items-center justify-center shadow-md">
                            {loading ? (
                                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                            ) : processedImageUrl ? (
                                <img
                                    src={processedImageUrl}
                                    alt="Processed"
                                    className="object-contain w-full h-full rounded-lg"
                                />
                            ) : (
                                <span className="text-gray-500">No image processed yet</span>
                            )}
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center mt-8">
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Home;
