import React, {useState} from 'react';
import {CHECK_AUTH, LOGIN_ROUTE} from "../constants/Requests";

import logo from "../assets/images/yugmedeals-logo.png";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserID, setUsername, setUserToken} from "../redux/userSlice";
import {HOME_PAGE} from "../constants/Routes";

const Login = () => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const checkAuth = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(CHECK_AUTH, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        const data = await response.json();
        if (response.ok) {
            dispatch(setUsername(data.message.username));
            dispatch(setUserID(data.message.id));
            dispatch(setUserToken(token));
            console.log("success", data.username);
            navigate(HOME_PAGE);
        } else {
            console.log("error", data);
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {

            return alert('Please fill in both email and password fields');

        }


        // API integration with error handling

        fetch(LOGIN_ROUTE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    localStorage.setItem('token', data.access_token);
                    checkAuth();
                } else {
                    // Login failed, display error message
                    alert(data.error);
                }
            }).catch(error => {
            console.error('Error logging in:', error);
            alert('An error occurred while logging in. Please try again later.');
        });

    }

    return (
        <div
            className="min-h-screen bg-gradient-to-br from-gray-900 to-black flex items-center justify-center px-4 py-12">
            <div className="max-w-md w-full bg-gray-800 shadow-lg rounded-lg px-8 py-10">
                <div className="flex justify-center mb-8">
                    <div className="flex flex-col">
                        <img src={logo} alt="Yugmedeals AI Store" className="w-48 h-auto"/>
                        <p className="text-right text-white">AI Store</p>
                    </div>
                </div>
                <p className="text-center text-gray-400 mb-10">Your AI-powered shopping companion</p>

                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label className="block text-gray-400 text-sm mb-2" htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-900 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-400 text-sm mb-2" htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter your password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-900 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        Sign In
                    </button>
                </form>

                <div className="text-center mt-8">
                    <p className="text-gray-400 text-sm">
                        Don't have an account? <a href="#" className="text-indigo-400 hover:underline">Sign up here</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
