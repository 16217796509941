import React, { useEffect } from 'react';

const HeadingLayout = ({title = "Yugmedeals AI"}) => {
    useEffect(() => {
    // Update the title
    document.title = title;

    // Update the favicon
    // const favicon = document.querySelector("link[rel='icon']");
    // if (favicon) {
    //   favicon.href = "/path/to/favicon.ico";
    // }
  }, []);
};

export default HeadingLayout;