import React, {useState} from 'react';
import {KYC_READER} from "../constants/Requests";
import {useSelector} from "react-redux";

const KYCReader = () => {
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.user);

    const [cardDropDown, setCardDropDown] = useState('ac');
    const [modelDropDown, setModelDropDown] = useState('');

    // Static options for the first dropdown
    const cardDropDownOptions = [
        {value: 'ac', label: 'AdhaarCard'},
        {value: 'pc', label: 'PanCard'},
        {value: 'dl', label: 'Driving License'},
        {value: 'vid', label: 'Voter ID'},
    ];

    // Dynamic options for the second dropdown based on first dropdown's selection
    const getAiModelOptions = (selected) => {
        switch (selected) {
            case 'ac':
                return [
                    {value: 'none', label: 'Select Model'},
                    {value: 'bocr', label: 'OPEN AI'},
                ];
            case 'pc':
                return [
                    {value: 'none', label: 'Select Model'},
                    {value: 'bocr', label: 'OPEN AI'},
                ];
            case 'dl':
                return [
                    {value: 'none', label: 'Select Model'},
                    {value: 'bocr', label: 'OPEN AI'},
                ];
            case 'vid':
                return [
                    {value: 'none', label: 'Select Model'},
                    {value: 'bocr', label: 'OPEN AI'},
                ];
            default:
                return [];
        }
    };

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
        setImageUrl('');
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = userData.token;
        setLoading(true);

        let cardDataResponse;

        try {
            const randomString = generateRandomString(10); // 10-character random string
            const filePath = `/assets/ai/${randomString}.jpg`;
            if (imageFile) {
                // Upload the image file
                const formData = new FormData();
                formData.append('image', imageFile);
                formData.append("path", filePath);
                formData.append('card', cardDropDown);
                formData.append('model', modelDropDown)

                const response = await fetch(KYC_READER, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                cardDataResponse = result.data;
            } else if (imageUrl) {
                // Process the image via URL
                const response = await fetch(KYC_READER, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        "image_url": imageUrl,
                        "path": filePath,
                        "card": cardDropDown,
                        "model": modelDropDown,
                    }),
                });

                const result = await response.json();
                cardDataResponse = result.data;
            }

            // If the response has a processed image URL, display it
            if (cardDataResponse) {
                setCardData(cardDataResponse);
                setImageFile(null);
            } else {
                alert('Failed to process the image. Please try again.');
            }
        } catch (error) {
            console.error('Error during image processing:', error);
            alert('An error occurred while processing the image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-700 flex justify-center items-center p-4">
            <div className="bg-white text-gray-800 rounded-lg shadow-2xl p-8 w-full max-w-5xl">
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-900">KYC Reader</h1>
                <p className="text-center text-gray-500 mb-10">
                    Upload an image or provide a URL, and get a KYC Card Details.
                </p>

                <div className="grid grid-cols-2 gap-10">
                    <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
                        <h2 className="text-lg font-semibold mb-4">Upload Image or Enter URL</h2>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="mb-4 block w-full text-sm text-gray-500
                            file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0
                            file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                            hover:file:bg-blue-100"
                        />
                        <div className="relative mb-4">
                            <input
                                type="text"
                                placeholder="Or paste image URL here"
                                value={imageUrl}
                                onChange={(e) => {
                                    setImageUrl(e.target.value);
                                    setImageFile(null);
                                }}
                                className="p-3 w-full border rounded-lg focus:ring focus:ring-blue-200"
                            />
                        </div>

                        {/* First Dropdown */}
                        <div className="mb-4">
                            <label htmlFor="cardDropdown" className="block text-gray-700 font-bold mb-2">
                                Select Card
                            </label>
                            <select
                                id="cardDropdown"
                                value={cardDropDown}
                                onChange={(e) => setCardDropDown(e.target.value)}
                                className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-200"
                            >
                                {cardDropDownOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Second Dropdown (Dynamic) */}
                        <div>
                            <label htmlFor="modelDropdown" className="block text-gray-700 font-bold mb-2">
                                Select Ai Model
                            </label>
                            <select
                                id="modelDropdown"
                                value={modelDropDown}
                                onChange={(e) => setModelDropDown(e.target.value)}
                                className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-200"
                            >
                                {getAiModelOptions(cardDropDown).map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-6 rounded-lg shadow-inner flex flex-col items-center">
                        <h2 className="text-lg font-semibold mb-4">Processed JSON</h2>
                        <div
                            className="w-72 h-72 bg-gray-300 rounded-lg flex items-center justify-center shadow-md p-4 overflow-auto">
                            {loading ? (
                                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                            ) : cardData ? (
                                <pre
                                    className="text-sm bg-white p-4 rounded-lg text-left shadow-lg w-full h-full overflow-auto">
                {JSON.stringify(cardData, null, 2)}
            </pre>
                            ) : (
                                <span className="text-gray-500">No JSON processed yet</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default KYCReader;
