import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Topbar from '../pages/global/Topbar';
import MyProSidebar from '../pages/global/sidebar/MyProSidebar';
import { useDispatch } from 'react-redux';
import { LOGIN_PAGE } from "../constants/Routes";
import { CHECK_AUTH } from "../constants/Requests";
import { setUserID, setUsername, setUserToken } from "../redux/userSlice";
import {MyProSidebarProvider} from "../pages/global/sidebar/sidebarContext";
import HeadingLayout from "../component/HeadingLayout";

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State to track loading status
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate(LOGIN_PAGE);
        return;
      }

      try {
        const response = await fetch(CHECK_AUTH, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          dispatch(setUsername(data.message.username));
          dispatch(setUserID(data.message.id));
          dispatch(setUserToken(token));
        } else {
          navigate(LOGIN_PAGE);
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
        navigate(LOGIN_PAGE);
      } finally {
        setLoading(false); // Set loading to false after the authentication check is complete
      }
    };

    checkAuth();
  }, [dispatch, navigate]);

  // Loading screen before sidebar and content are loaded
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
          backgroundColor: '#fff', // Optional: Add background to loading screen
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <MyProSidebarProvider>
      <HeadingLayout/>
      <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
        {/* Sidebar */}

        {/* Main content with topbar */}
        <Box sx={{ flexGrow: 1 }}>
          <Topbar />
          <Box component="main" sx={{ backgroundColor: '#f5f5f5' }}>
            <Outlet /> {/* Render the page content once loading is complete */}
          </Box>
        </Box>
      </Box>
    </MyProSidebarProvider>
  );
};

export default MainLayout;
